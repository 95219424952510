import axios from 'axios';
import { createUrl } from './url';
import { SOURCES } from './constants';


const schedule = {
  get: (queryParams) => {
    const source = SOURCES.FIREBASE;
    const route = '/retrive_booking_by_date/';
    const url = createUrl(source, route, queryParams);

    return axios.get(url);
  },
};

const bigQuery = {
  getGameSummary: (gameId, league) => {
    const source = SOURCES.FIREBASE;
    const route = 'sportsRadarGameSummaryProxy';
    const queryParams = { league, gameId };
    const url = createUrl(source, route, queryParams);

    return axios.get(url);
  },
  getPlayerLines: (gameId, league) => {
    const source = SOURCES.FIREBASE;
    const route = 'app/boxscore';
    const queryParams = { league, gameId };
    const url = createUrl(source, route, queryParams);

    return axios.get(url);
  },
  postBoxscoreEdits: (league, players, gameId) => {
    const source = SOURCES.FIREBASE;
    const route = 'app/boxscore';
    const body = { league, players, gameId };
    const url = createUrl(source, route);

    return axios({
      method: 'post',
      url,
      data: body,
    });
  },
};

const sources = { schedule, bigQuery };

export default sources;
