export const authConfig = {
  issuer: 'https://sportsiq.okta.com/oauth2/default',
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: process.env.REACT_APP_CLIENT_ID,
};

export const signInConfig = {
  baseUrl: 'https://sportsiq.okta.com',
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  authParams: {},
  logo: process.env.REACT_APP_CLIENT_ID === 'metric' ? 'Logo-metric.png' : 'Logo-black-auth.png',
};
