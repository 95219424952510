import * as React from 'react';
import { Box } from '@mui/material/';
import { TeamLogo } from 'utilities';


const styles = () => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  color: 'common.white',
  alignItems: 'center',
  p: 1,
  borderRadius: 1,
});

export const TeamWidget = ({ league, team }) => (
  <Box sx={styles(league, team)}>
    {team}
    <TeamLogo league={league} team={team} />
  </Box>
);
