import url from 'url';
import { SERVER_MAP } from './constants';


export const createUrl = (host, route, query) => (
  url.format({
    protocol: 'https',
    host: SERVER_MAP[host],
    pathname: route,
    query,
  })
);
