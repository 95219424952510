import React from 'react';
import MuiButton from '@mui/material/Button';


export const Button = ({ title, color, disabled, onClick, varient }) => (
  <MuiButton
    color={color}
    varient={varient}
    disabled={disabled}
    onClick={onClick}
    sx={{
      backgroundColor: `${color}.main`,
      color: `${color}.contrastText`,
      '&:hover': {
        backgroundColor: `${color}.dark`,
      },
      '&:focus': { outline: 'none' },
    }}
  >
    {title}
  </MuiButton>
);
