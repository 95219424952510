import { createAsyncThunk } from '@reduxjs/toolkit';
import sources from 'api/sources';


export const getBoxscoreData = createAsyncThunk(
  'settlements/getBoxscoreData',
  async ({ id, league }) => {
    const { data } = await sources.bigQuery.getPlayerLines(id, league);
    return data;
  },
);

export const postBoxscoreEdits = createAsyncThunk(
  'settlements/postBoxscoreEdits',
  async ({ league, players, gameId }) => {
    const { data } = await sources.bigQuery.postBoxscoreEdits(league, players, gameId);
    return data;
  },
);
