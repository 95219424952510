import { configureStore } from '@reduxjs/toolkit';
import gameIntercept from 'middleware/setSelectedGameInterceptor';
import scheduleIntercept from 'middleware/getScheduleInterceptor';
import logger from 'middleware/logger';
import rootReducer from './reducers';


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(gameIntercept, scheduleIntercept, logger),
});

export default store;
