import clsx from 'clsx';
import { getCellValue, isFieldEdited } from '../helpers';


const DEFAULT_VALUES_MAP = {
  fullName: '',
  passingTds: '-',
  passAttempts: '-',
  passCompletions: '-',
  passingYards: '-',
  rushingTds: '-',
  rushingYards: '-',
  qbRushingYards: '-',
  rushAttempts: '-',
  fieldGoalAttempts: '-',
  fieldGoalsMade: '-',
  receivingTds: '-',
  receivingTargets: '-',
  receivingReceptions: '-',
  receivingYards: '-',
  isCancelled: false,
};

export const KEY_PATH_MAP = {
  fullName: ['name'],
  passingTds: ['qtdp'],
  passAttempts: ['qpa'],
  passCompletions: ['qpc'],
  passingYards: ['qpy'],
  rushingYards: ['ry'],
  rushAttempts: ['ra'],
  rushingTds: ['rtd'],
  fieldGoalAttempts: ['fga'],
  fieldGoalsMade: ['fgm'],
  receivingTds: ['rtd'],
  receivingTargets: ['rra'],
  receivingReceptions: ['rrc'],
  receivingYards: ['rry'],
  isCancelled: ['isCancelled'],
};

export const KEY_TYPECAST_MAP = {
  passingTds: (val) => parseInt(val, 10),
  passAttempts: (val) => parseInt(val, 10),
  passCompletions: (val) => parseInt(val, 10),
  passingYards: (val) => parseInt(val, 10),
  rushingYards: (val) => parseInt(val, 10),
  rushAttempts: (val) => parseInt(val, 10),
  rushingTds: (val) => parseInt(val, 10),
  fieldGoalAttempts: (val) => parseInt(val, 10),
  fieldGoalsMade: (val) => parseInt(val, 10),
  receivingTds: (val) => parseInt(val, 10),
  receivingTargets: (val) => parseInt(val, 10),
  receivingReceptions: (val) => parseInt(val, 10),
  receivingYards: (val) => parseInt(val, 10),
  isCancelled: (val) => !!val,
};

export const DEFAULT_COLUMN = (edits, players) => ({
  headerAlign: 'left',
  align: 'left',
  editable: true,
  sortable: true,
  cellClassName: ({ id, field, value }) => clsx('', {
    edited: isFieldEdited(KEY_PATH_MAP, id, field, value, edits, players),
  }),
});

export const getColumns = (edits, players) => [
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'Full Name',
    headerName: 'Name',
    width: 170,
    editable: false,
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.fullName, DEFAULT_VALUES_MAP.fullName),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'passingTds',
    headerName: 'QTDP',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.passingTds, DEFAULT_VALUES_MAP.passingTds),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'passAttempts',
    headerName: 'QPA',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.passAttempts, DEFAULT_VALUES_MAP.passAttempts),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'passCompletions',
    headerName: 'QPC',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.passCompletions, DEFAULT_VALUES_MAP.passCompletions),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'passingYards',
    headerName: 'QPY',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.passingYards, DEFAULT_VALUES_MAP.passingYards),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'rushingTds',
    headerName: 'TDR',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.rushingTds, DEFAULT_VALUES_MAP.rushingTds),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'rushingYards',
    headerName: 'RY',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.rushingYards, DEFAULT_VALUES_MAP.rushingYards),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'rushAttempts',
    headerName: 'RA',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.rushAttempts, DEFAULT_VALUES_MAP.rushAttempts),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'fieldGoalAttempts',
    headerName: 'FGA',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.fieldGoalAttempts, DEFAULT_VALUES_MAP.fieldGoalAttempts),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'fieldGoalsMade',
    headerName: 'FGM',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.fieldGoalsMade, DEFAULT_VALUES_MAP.fieldGoalsMade),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'receivingTds',
    headerName: 'RTD',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.receivingTds, DEFAULT_VALUES_MAP.receivingTds),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'receivingTargets',
    headerName: 'RRA',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.receivingTargets, DEFAULT_VALUES_MAP.receivingTargets),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'receivingReceptions',
    headerName: 'RRC',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.receivingReceptions, DEFAULT_VALUES_MAP.receivingReceptions),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'receivingYards',
    headerName: 'RRY',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.receivingYards, DEFAULT_VALUES_MAP.receivingYards),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'isCancelled',
    headerName: 'Cancelled',
    align: 'center',
    editable: true,
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.isCancelled, DEFAULT_VALUES_MAP.isCancelled),
    type: 'boolean',
  },
];
