export const teamColorMap = {
  NHL: {
    ANA: '#FC6600',
    ARI: '#8E0028',
    BOS: '#FDC423',
    BUF: '#032E62',
    CAR: '#E03A3E',
    CBJ: '#03285C',
    CGY: '#E03A3E',
    CHI: '#CE1126',
    COL: '#6F263D',
    DAL: '#0D6A4E',
    DET: '#EC2026',
    EDM: '#053777',
    FLA: '#021E41',
    LA: '#000000',
    MIN: '#EDAA03',
    MTL: '#032366',
    NJ: '#E03A3E',
    NSH: '#041E42',
    NYI: '#F99D1C',
    NYR: '#EE3A43',
    OTT: '#E4173E',
    PHI: '#FA6116',
    PIT: '#FDB71D',
    SJ: '#09535D',
    STL: '#FDCA38',
    TB: '#063E7D',
    TOR: '#053777',
    VAN: '#148852',
    VGK: '#565656',
    WPG: '#55565A',
    WSH: '#041E42',
  },
  NFL: {
    ARI: '#B1063A',
    ATL: '#BD0C17',
    BAL: '#24135F',
    BUF: '#DA2127',
    CAR: '#1685CA',
    CHI: '#1D1B40',
    CIN: '#FC2700',
    CLE: '#E34912',
    DAL: '#032A5B',
    DEN: '#E56224',
    DET: '#106DB0',
    GB: '#F6BE0C',
    HOU: '#06192E',
    IND: '#053B7B',
    JAC: '#107198',
    KC: '#C9243F',
    LA: '#12264B',
    LAC: '#08214A',
    LV: '#000000',
    MIA: '#168C95',
    MIN: '#4F2682',
    NE: '#0D254C',
    NO: '#000000',
    NYG: '#04356E',
    NYJ: '#0C371D',
    PHI: '#074D56',
    PIT: '#0A5197',
    SEA: '#76BB41',
    SF: '#AF1E2B',
    TB: '#C80F2E',
    TEN: '#5085BC',
    WAS: '#782F40',
  },
  MLB: {
    ARI: '#A71930',
    ATL: '#CE1141',
    BAL: '#000000',
    BOS: '#0D2B56',
    CHC: '#CC3433',
    CIN: '#C6011F',
    CLE: '#E31937',
    COL: '#758690',
    CWS: '#000000',
    DET: '#0C2C56',
    HOU: '#EB6E1F',
    KC: '#BB9658',
    LAA: '#BA0021',
    LAD: '#005A9C',
    MIA: '#EF2F3C',
    MIL: '#B6922E',
    MIN: '#002B5C',
    NYM: '#F5570F',
    NYY: '#132448',
    OAK: '#E9B027',
    PHI: '#005A9C',
    PIT: '#FDB827',
    SD: '#002D62',
    SEA: '#005C5C',
    SF: '#FD5A1E',
    STL: '#15345E',
    TB: '#092C5C',
    TEX: '#C40B1C',
    TOR: '#134A8E',
    WSH: '#03275D',
  },
  NBA: {
    ATL: '#E03A3E',
    BKN: '#000000',
    BOS: '#008248',
    CHA: '#26245C',
    CHI: '#CE1141',
    CLE: '#6F263D',
    DAL: '#003DA6',
    DEN: '#0D2240',
    DET: '#DD0031',
    GSW: '#0068B3',
    HOU: '#C3002F',
    IND: '#FDBB30',
    LAC: '#EC184C',
    LAL: '#FDB927',
    MEM: '#5A73A4',
    MEN: '#5D76A9',
    MIA: '#8B2332',
    MIL: '#00471A',
    MIN: '#276092',
    NOP: '#002B5C',
    NYK: '#FF671B',
    OKC: '#0071CE',
    ORL: '#8E9090',
    PHI: '#006BB5',
    PHX: '#F9A01B',
    POR: '#000000',
    SAC: '#5B2B82',
    SAS: '#000000',
    TOR: '#070B0C',
    UTA: '#2B5134',
    WAS: '#0D2240',
  },
  WNBA: {
    ATL: '#0C2B5A',
    CHI: '#FFCD00',
    CON: '#BF2F38',
    DAL: '#BED600',
    IND: '#E03A3E',
    LAS: '#552583',
    LVA: '#BF965C',
    MIN: '#266092',
    NYL: '#73D2B7',
    PHO: '#CD5A13',
    SEA: '#3C5239',
    WAS: '#737374',
  },
};
