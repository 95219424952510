import {
  commitNewEdit,
  clearAllEdits,
  undoLastEdit,
  setValidationMessage,
} from '../reducers/settlement/settlementSlice';


export const mapStateToProps = (state) => ({
  validationMessage: state.settlements.validationMessage,
  selectedGame: state.settlements.selectedGame,
});

export const mapDispatchToProps = {
  commitNewEdit,
  clearAllEdits,
  undoLastEdit,
  setValidationMessage,
};
