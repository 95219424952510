import { createSlice } from '@reduxjs/toolkit';
import { getBoxscoreData, postBoxscoreEdits } from './actions';
import { SAVE_ERROR_MESSAGE } from './constants';
import { updateSaveStatusToPending, updateSaveStatusToSaved, updateSaveStatusToUnsaved } from './helpers';


export const initialState = {
  selectedGame: null,
  loadingBoxscoreData: false,
  selectedMarket: 0,
  edits: { home: [], away: [] },
  savingEdits: { home: false, away: false },
  players: { home: [], away: [] },
  scoring: {},
  validationMessage: { home: null, away: null },
};

const settlementsSlice = createSlice({
  name: 'settlements',
  initialState,
  reducers: {
    setSelectedGame(state, action) {
      state.selectedGame = action.payload;
      state.players = { home: [], away: [] };
      state.validationMessage = { home: null, away: null };
      state.edits = { home: [], away: [] };
    },
    setSelectedMarket(state, action) {
      const value = action.payload;
      state.selectedMarket = value;
    },
    setValidationMessage(state, action) {
      const { team } = action.payload;
      state.validationMessage[team] = action.payload.message;
    },
    commitNewEdit(state, action) {
      const { team } = action.payload;
      state.edits[team].push(action.payload.commit);
    },
    clearAllEdits(state, action) {
      const { team } = action.payload;
      state.edits[team] = state.edits[team].filter((edit) => edit.saveStatus !== 'unsaved');
    },
    undoLastEdit(state, action) {
      const { team } = action.payload;
      if (state.edits[team][state.edits[team].length - 1].saveStatus === 'unsaved') {
        state.edits[team].pop();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoxscoreData.pending, (state) => {
        state.loadingBoxscoreData = true;
      })
      .addCase(getBoxscoreData.fulfilled, (state, action) => {
        state.loadingBoxscoreData = false;
        state.players = action.payload.players;
        state.scoring = action.payload.scoringSummary;
        console.log(state.scoring);
      })
      .addCase(getBoxscoreData.rejected, (state) => {
        state.loadingBoxscoreData = false;
        state.players = [];
        state.scoring = {};
      })
      .addCase(postBoxscoreEdits.pending, (state, action) => {
        const { team } = action.meta.arg;
        state.savingEdits[team] = true;
        state.edits[team] = state.edits[team].map(updateSaveStatusToPending);
      })
      .addCase(postBoxscoreEdits.fulfilled, (state, action) => {
        const { team } = action.meta.arg;
        state.savingEdits[team] = false;
        state.edits[team] = state.edits[team].map(updateSaveStatusToSaved);
        state.validationMessage[team] = null;
      })
      .addCase(postBoxscoreEdits.rejected, (state, action) => {
        const { team } = action.meta.arg;
        state.edits[team] = state.edits[team].map(updateSaveStatusToUnsaved);
        state.savingEdits[team] = false;
        state.validationMessage[team] = SAVE_ERROR_MESSAGE;
      });
  },
});

export const {
  setSelectedGame,
  setSelectedMarket,
  setValidationMessage,
  commitNewEdit,
  clearAllEdits,
  undoLastEdit,
} = settlementsSlice.actions;

export default settlementsSlice.reducer;
