import { getBoxscoreData } from './reducers/settlement/actions';
import { setSelectedGame } from './reducers/settlement/settlementSlice';


export const mapStateToProps = (state) => ({
  selectedGame: state.settlements.selectedGame,
  selectedMarket: state.settlements.selectedMarket,
  loadingBoxscoreData: state.settlements.loadingBoxscoreData,
  edits: state.settlements.edits,
  players: state.settlements.players,
});

export const mapDispatchToProps = {
  setSelectedGame,
  getBoxscoreData,
};
