import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Toolbar from 'commonComponents/Toolbar';
import MarketTabs from './MarketTabs';
import GameNav from './GameNav';
import { useStyles } from './styles';
import MarketContent from './MarketContent';


export const Settlements = ({
  getBoxscoreData,
  selectedMarket,
  loadingBoxscoreData,
  players,
  edits,
  selectedGame,
}) => {
  const history = useHistory();
  const { date } = useParams();

  useEffect(() => {
    if (selectedGame) {
      getBoxscoreData(selectedGame);
      history.push({
        pathname: `/${date}/${selectedGame.id}`,
      });
    }
  }, [getBoxscoreData, selectedGame]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <GameNav />
      <div className={classes.tableContainer}>
        <Toolbar />
        <MarketTabs />
        <MarketContent
          selectedGame={selectedGame}
          loadingBoxscoreData={loadingBoxscoreData}
          players={players}
          edits={edits}
          selectedMarket={selectedMarket}
        />
      </div>
    </div>
  );
};
