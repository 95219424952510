import { getSchedule } from 'pages/Settlements/reducers/date/actions';


export const mapStateToProps = (state) => ({
  schedule: state.date.schedule,
  loadingSchedule: state.date.loadingSchedule,
  selectedDate: state.date.selectedDate,
  timezone: state.date.timezone,
  client: state.date.client,
});

export const mapDispatchToProps = {
  getSchedule,
};
