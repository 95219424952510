import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'index.css';


document.body.style.backgroundColor = '#212121';
document.body.style.margin = 0;

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
