export const updateSaveStatusToPending = (edit) => {
  if (edit.saveStatus === 'unsaved') edit.saveStatus = 'pending';
  return edit;
};

export const updateSaveStatusToSaved = (edit) => {
  if (edit.saveStatus === 'pending') edit.saveStatus = 'saved';
  return edit;
};

export const updateSaveStatusToUnsaved = (edit) => {
  if (edit.saveStatus === 'pending') edit.saveStatus = 'unsaved';
  return edit;
};
