import React from 'react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { authConfig, signInConfig } from 'oktaAuth/config';
import { Route, useHistory } from 'react-router-dom';
import Settlements from 'pages/Settlements';
import Login from './oktaAuth/Login';


const Routes = () => {
  const oktaAuth = new OktaAuth(authConfig);
  const history = useHistory();

  const onAuthRequired = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute exact path="/:date?/:game?" component={Settlements} />
      <Route exact path="/login" render={() => (<Login config={signInConfig} />)} />
      <Route exact path="/implicit/callback" component={LoginCallback} />
    </Security>
  );
};

export default Routes;
