import * as React from 'react';
import { Paper } from '@mui/material';
import Loader from 'commonComponents/Loader';
import List from '@mui/material/List';
import LeagueList from './LeagueList';
import Header from './Header';
import { NO_GAMES_MESSAGE, SUPPORTED_LEAUGUES } from './constants';


const listStyle = {
  m: 0,
  p: 0,
  overflow: 'scroll',
};

const style = {
  width: '220px',
  height: '100vh',
  overflow: 'none',
  display: 'flex',
  flexDirection: 'column',
  '.loader': { m: 'auto', color: 'loader', fontWeight: 'bold' },
};

export const GameNav = ({
  schedule,
  loadingSchedule,
  getSchedule,
  selectedDate,
  timezone,
  client,
}) => {
  React.useEffect(() => {
    if (selectedDate) {
      getSchedule({ date: selectedDate, timezone, client });
    }
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const supportedLeagues = schedule.filter((league) => SUPPORTED_LEAUGUES.includes(league.league));

  if (supportedLeagues.length || loadingSchedule) {
    return (
      <Paper sx={style} square variant="outlined">
        <Header />
        {
          loadingSchedule
            ? <Loader />
            : (
              <List sx={listStyle}>
                {
                  supportedLeagues.map((league) => (
                    <LeagueList key={league.league} leagueSchedule={league} />
                  ))
                }
              </List>
            )
        }
      </Paper>
    );
  }
  return (
    <Paper sx={style} square variant="outlined">
      <Header />
      <div className="loader">{NO_GAMES_MESSAGE}</div>
    </Paper>
  );
};
