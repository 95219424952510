import React, { useCallback, useMemo } from 'react';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useStyles, paperStyles } from './styles';
import { validateEdit } from './validations';
import CustomFooter from './Footer';
import CustomHeader from './Header';
import { mergeEdits } from './helpers';
import { LEAGUE_GET_COLUMN_MAP, LEAGUE_KEY_PATH_MAP, LEAGUE_TYPECAST_MAP } from './constants';


const getRowClassName = ({ row }) => {
  const className = row.isCancelled ? 'cancelled' : '';
  return className;
};

export const PlayerTable = ({
  players,
  edits,
  team,
  commitNewEdit,
  clearAllEdits,
  undoLastEdit,
  validationMessage,
  setValidationMessage,
  selectedGame,
}) => {
  const { league } = selectedGame;

  const handleCellEditCommit = useCallback(
    ({ id, field, value, formattedValue }) => {
      const typeCastedValue = LEAGUE_TYPECAST_MAP[league][field](value);
      const { valid, errorMessage } = validateEdit(typeCastedValue, field);

      if (!valid) setValidationMessage({ team, message: errorMessage });

      if (formattedValue !== typeCastedValue && valid) { // formattedValue is the previous value of the cell
        commitNewEdit({ commit: { id, field, value: typeCastedValue, saveStatus: 'unsaved' }, team });
        setValidationMessage({ team, message: null });
      }
    }, [commitNewEdit, setValidationMessage, team, league],
  );

  const keyMap = LEAGUE_KEY_PATH_MAP[league];
  const getColumns = LEAGUE_GET_COLUMN_MAP[league];

  const classes = useStyles();

  const rows = mergeEdits(keyMap, edits, players);
  const columns = useMemo(() => getColumns(edits, players), [getColumns, edits, players]);


  return (
    <>
      <Paper elevation={1} sx={paperStyles}>
        <DataGrid
          autoHeight
          className={classes.table}
          rowHeight={24}
          rows={rows}
          columns={columns}
          getRowClassName={getRowClassName}
          onCellEditCommit={handleCellEditCommit}
          components={{ Footer: CustomFooter, Toolbar: CustomHeader }}
          componentsProps={{
            footer: { edits, clearAllEdits, undoLastEdit, team, validationMessage, setValidationMessage },
            toolbar: { selectedGame, team, rows, edits },
          }}
        />
      </Paper>
    </>
  );
};
