import * as React from 'react';
import Box from '@mui/material/Box';
import Button from 'commonComponents/Button';
import Alert from 'commonComponents/Alert';


const styles = {
  display: 'flex',
  p: 1,
  '&& > *': {
    mr: 1,
    '&:last-child': {
      flexGrow: 1,
      mr: 0,
    },
  },
};



export const CustomFooter = ({ edits, clearAllEdits, undoLastEdit, team, validationMessage, setValidationMessage }) => {
  const unsavedEdits = edits.filter((edit) => edit.saveStatus === 'unsaved');

  return (
    <Box sx={styles}>
      <Button
        varient="contained"
        color="primary"
        disabled={!unsavedEdits.length}
        title="Clear Edits"
        onClick={() => clearAllEdits({ team })}
      />
      <Button
        varient="contained"
        color="secondary"
        disabled={!unsavedEdits.length}
        title="Undo"
        onClick={() => undoLastEdit({ team })}
      />
      {
          validationMessage[team]
            ? <Alert onClose={() => setValidationMessage({ team, message: null })} severity="error" message={validationMessage[team]} />
            : <div />
        }
    </Box>
  );
};
