import clsx from 'clsx';


const DEFAULT_VALUES_MAP = {
  team: '',
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  T: 0,
};

export const KEY_PATH_MAP = {
  team: ['team'],
  1: ['q1'],
  2: ['q2'],
  3: ['q3'],
  4: ['q4'],
  T: ['total'],
};

export const KEY_TYPECAST_MAP = {
  1: (val) => parseInt(val, 10),
  2: (val) => parseInt(val, 10),
  3: (val) => parseInt(val, 10),
  4: (val) => parseInt(val, 10),
  T: (val) => parseInt(val, 10),
};

export const DEFAULT_COLUMN = (edits, players) => ({
  headerAlign: 'left',
  align: 'left',
  editable: true,
  sortable: true,
  cellClassName: ({ id, field, value }) => clsx('', {
    // edited: isFieldEdited(KEY_PATH_MAP, id, field, value, edits, players),
  }),
});

export const getColumns = (edits, players) => [
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'team',
    headerName: 'Team',
    width: 170,
    editable: false,
    // valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.team, DEFAULT_VALUES_MAP.team),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'q1',
    headerName: '1',
    // valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q1, DEFAULT_VALUES_MAP.q1),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'q2',
    headerName: '2',
    // valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q2, DEFAULT_VALUES_MAP.q2),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'q3',
    headerName: '3',
    // valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q3, DEFAULT_VALUES_MAP.q3),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'q4',
    headerName: '4',
    // valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q4, DEFAULT_VALUES_MAP.q4),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'total',
    headerName: 'T',
    // valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.total, DEFAULT_VALUES_MAP.total),
  },
];
