import { combineReducers } from 'redux';
import dateReducer from './pages/Settlements/reducers/date/dateSlice';
import settlementsReducer from './pages/Settlements/reducers/settlement/settlementSlice';


const rootReducer = combineReducers({
  date: dateReducer,
  settlements: settlementsReducer,
});

export default rootReducer;
