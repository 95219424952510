import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles((theme) => ({
  table: {
    flexGrow: 1,
    '& .edited': {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    },
    '& .MuiDataGrid-toolbarContainer > button': {
      color: theme.palette.primary.light,
      '&:focus': { outline: 'none' },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.common.white,
    },
    '& .cancelled': {
      backgroundColor: theme.palette.transparentError.main,
    },
  },
}));

export const paperStyles = {
  backgroundColor: 'grey.900',
  display: 'flex',
  mt: 1,
  ml: 1,
  mr: 1,
};
