// import clsx from 'clsx';
import { getCellValue } from 'pages/Settlements/PlayerTable/helpers';


const DEFAULT_VALUES_MAP = {
  team: '',
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  T: 0,
};

export const KEY_PATH_MAP = {
  team: ['team'],
  q1: ['q1'],
  q2: ['q2'],
  q3: ['q3'],
  q4: ['q4'],
  total: ['total'],
};

export const KEY_TYPECAST_MAP = {
  1: (val) => parseInt(val, 10),
  2: (val) => parseInt(val, 10),
  3: (val) => parseInt(val, 10),
  4: (val) => parseInt(val, 10),
  T: (val) => parseInt(val, 10),
};

export const DEFAULT_COLUMN = () => ({
  headerAlign: 'left',
  align: 'left',
  editable: false,
  sortable: true,
});

export const getColumns = () => [
  {
    ...DEFAULT_COLUMN(),
    field: 'team',
    headerName: 'Team',
    width: 250,
    editable: false,
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.team, DEFAULT_VALUES_MAP.team),
  },
  {
    ...DEFAULT_COLUMN(),
    field: 'q1',
    headerName: 'Q1',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q1, DEFAULT_VALUES_MAP.q1),
  },
  {
    ...DEFAULT_COLUMN(),
    field: 'q2',
    headerName: 'Q2',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q2, DEFAULT_VALUES_MAP.q2),
  },
  {
    ...DEFAULT_COLUMN(),
    field: 'q3',
    headerName: 'Q3',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q3, DEFAULT_VALUES_MAP.q3),
  },
  {
    ...DEFAULT_COLUMN(),
    field: 'q4',
    headerName: 'Q4',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.q4, DEFAULT_VALUES_MAP.q4),
  },
  {
    ...DEFAULT_COLUMN(),
    field: 'total',
    headerName: 'Total',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.total, DEFAULT_VALUES_MAP.total),
  },
];
