export const VALIDATION_MESSAGES = {
  NOT_A_NUMBER: (field) => `You must enter a number under ${field}`,
  NEGATIVE_NUMBER: (field) => `You must enter a positive number under ${field}`,
  BLANK: (field) => `You must enter a non-empty value under ${field}`,
  MINUTES: (field) => `${field} must be formatted as MM:SS`,
};

export const FIELD_VALIDATION_MAP = {
  minutes: ['minutes'],
};

const minutesRegex = /^([0-5]?\d):([0-5]\d)$/;

export const validateEdit = (value, field) => {
  if (Number.isNaN(value)) return { valid: false, errorMessage: VALIDATION_MESSAGES.NOT_A_NUMBER(field) };
  if (value < 0) return { valid: false, errorMessage: VALIDATION_MESSAGES.NEGATIVE_NUMBER(field) };
  if (value === '') return { valid: false, errorMessage: VALIDATION_MESSAGES.BLANK(field) };

  if (FIELD_VALIDATION_MAP.minutes.includes(field)) {
    const match = minutesRegex.test(value);
    if (!match) return { valid: false, errorMessage: VALIDATION_MESSAGES.MINUTES(field) };
  }

  return { valid: true };
};
