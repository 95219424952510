import React from 'react';
import { Alert as MuiAlert } from '@mui/material';


const styles = {
  pt: 0,
  pb: 0,
  '& *:focus': {
    outline: 'none',
  },
};

export const Alert = ({ severity, message, onClose }) => (
  <MuiAlert sx={styles} onClose={onClose} severity={severity}>
    {message}
  </MuiAlert>
);
