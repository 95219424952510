import * as React from 'react';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import GameWidget from '../GameWidget';


const headerStyles = {
  backgroundColor: 'grey.900',
  p: 0,
};

const selectedGameStyles = {
  backgroundColor: 'primary.main',
  '&:hover': { backgroundColor: 'primary.dark' },
};

export const LeagueList = ({ leagueSchedule, selectedGame, setSelectedGame }) => {
  const { league, games } = leagueSchedule;
  const [open, setOpen] = React.useState(true);
  const { game: gameId } = useParams();

  const hideShow = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (leagueSchedule && !selectedGame) {
      const linkedGame = leagueSchedule.games.find((game) => game.id === gameId);
      if (linkedGame) setSelectedGame(linkedGame);
    }
  }, [leagueSchedule]);

  return (
    <>
      <ListItemButton sx={headerStyles} onClick={hideShow}>
        <ListItemText sx={{ pl: 1 }} primary={league} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ p: 0, m: 0 }}>
          {games.map((game) => {
            let buttonStyles = { p: 0.5 };
            if (selectedGame && game.id === selectedGame.id) {
              buttonStyles = { ...buttonStyles, ...selectedGameStyles };
            }
            return (
              <div key={game.id}>
                <ListItemButton sx={buttonStyles} onClick={() => setSelectedGame(game)}>
                  <GameWidget game={game} />
                </ListItemButton>
                <Divider />
              </div>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
