import React from 'react';


export function LogoStyle(color, source, direction) {
  if (source === 'desktop') {
    return {
      background: 'transparent',
      height: '48px',
      width: '48px',
      padding: '5px',
    };
  }
  return {
    background: `linear-gradient(to ${direction}, ${color}, transparent)`,
    height: '50px',
    width: '50px',
    padding: '5px',
  };
}

export function DesktopAwayAbbrScoreStyle(color) {
  return {
    background: color,
    width: '65px',
    color: 'white',
    fontWeight: '600',
    fontSize: 'larger',
    borderTopLeftRadius: '11px',
  };
}

export function DesktopHomeAbbrScoreStyle(color) {
  return {
    background: color,
    width: '65px',
    color: 'white',
    fontWeight: '600',
    fontSize: 'larger',
    borderTopRightRadius: '11px',
  };
}

export function DesktopAwayScoreAbbr(props) {
  return (
    <div style={DesktopAwayAbbrScoreStyle(props.color)}>
      {props.team}
    </div>
  );
}

export function DesktopHomeScoreAbbr(props) {
  return (
    <div style={DesktopHomeAbbrScoreStyle(props.color)}>
      {props.team}
    </div>
  );
}

export function DesktopLeftTriangle(color) {
  return {
    width: '35px',
    borderStyle: 'solid',
    borderWidth: '89px 0 0 35px',
    borderColor: `transparent transparent transparent ${color}`,
  };
}

export function DesktopRightTriangle(color) {
  return {
    width: '35px',
    borderStyle: 'solid',
    borderWidth: '0 0 89px 35px',
    borderColor: `transparent transparent ${color} transparent`,
  };
}


export const TeamLogo = ({ league, team }) => (
  <img
    style={{
      background: 'transparent',
      height: '30px',
      width: '30px',
    }}
    src={`${process.env.PUBLIC_URL}/Logos/${league}/${team}.${league === 'NFL' || league === 'NHL' ? 'svg' : 'png'}`}
    alt=""
  />
);

export const LeagueLogo = ({ sports }) => (
  <img
    style={{ background: 'transparant', height: '65px', padding: '5px' }}
    src={`${process.env.PUBLIC_URL}/Logos/${sports}_logo.png`}
    alt=""
  />
);
