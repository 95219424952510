import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Toolbar as MaterialToolbar, AppBar, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import siqLogo from 'siqLogo.png';
import { useOktaAuth } from '@okta/okta-react';


const useStyles = makeStyles(() => ({
  logo: {
    flexGrow: 1,
  },
}));

const style = {
  flexGrow: 1,
  color: '#fff',
  height: '64px',
  maxHeight: '64px',
  position: 'sticky',
  top: 0,
};

export const Toolbar = () => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();

  const logout = async () => {
    oktaAuth.signOut('/');
  };

  return (
    <AppBar sx={style}>
      <MaterialToolbar>
        <Link to={{ pathname: '/' }}>
          <img alt="" src={siqLogo} height="35" />
        </Link>
        <div className={classes.logo} />
        <Tooltip title="Logout">
          <ExitToAppIcon className="logout" onClick={() => { logout(); }} />
        </Tooltip>
      </MaterialToolbar>
    </AppBar>
  );
};
