import * as React from 'react';
import { Paper } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dateAdapter from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';


const styles = {
  minHeight: '62px',
  width: '100%',
  display: 'flex',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  alignItems: 'center',
  justiftyContent: 'center',
  '& > div': {
    pl: '25px',
  },
  input: {
    textAlign: 'center',
    p: 1,
    maxWidth: '100px',
  },
  button: {
    '&:focus': { outline: 'none' },
  },
};

export const Header = ({ selectedDate, setSelectedDate }) => {
  const history = useHistory();
  const { date, game } = useParams();

  React.useEffect(() => {
    if (date !== selectedDate && moment(date).isValid()) {
      setSelectedDate(date);
    } else if (!selectedDate && !moment(date).isValid()) {
      const dateString = moment().format('MM-DD-YYYY');
      setSelectedDate(dateString);
      history.push({ pathname: `/${dateString}/${game || ''}` });
    } else {
      history.push({ pathname: `/${selectedDate}/${game || ''}` });
    }
  }, [selectedDate, game, date, history, setSelectedDate]);

  return (
    <Paper sx={styles} square variant="outlined">
      <LocalizationProvider dateAdapter={dateAdapter}>
        <DatePicker
          sx={{ ml: 1 }}
          value={selectedDate}
          minDate={new Date('2017-01-01')}
          onChange={(newValue) => {
            if (newValue) {
              const newDateString = newValue.toLocaleDateString().replaceAll('/', '-');
              setSelectedDate(newDateString);
              history.push({
                pathname: `/${newDateString}/${game || ''}`,
              });
            }
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Paper>
  );
};
