import * as React from 'react';
import TeamWidget from 'pages/Settlements/GameNav/TeamWidget';
import Box from '@mui/material/Box';
import moment from 'moment';


const styles = {
  display: 'flex',
  flexDireciton: 'row',
  flexGrow: 1,
  height: '60px',
  width: '200px',
  backgroundColor: 'transparent',
};

const middleStyles = {
  flexDirection: 'column',
  flexGrow: 1,
  display: 'flex',
  margin: 'auto',
  alignItems: 'center',
  '& > .time': {
    fontSize: 'typography.body2.fontSize',
    fontWeight: '550',
    color: 'grey.500',
    justifySelf: 'end',
  },
  '& > .date': {
    fontWeight: '600',
    color: 'grey.400',
    justifySelf: 'end',
  },
};

export const GameWidget = ({ game }) => {
  const { league, scheduled, away, home } = game;

  let displayDate;

  const today = moment();
  const yesterday = moment().subtract(1, 'days');
  const tomorrow = moment().add(1, 'days');

  const gameDay = moment(scheduled);

  if (gameDay.isSame(today, 'day')) {
    displayDate = 'Today';
  } else if (gameDay.isSame(yesterday, 'day')) {
    displayDate = 'Yesterday';
  } else if (gameDay.isSame(tomorrow, 'day')) {
    displayDate = 'Tomorrow';
  } else { displayDate = gameDay.format('ddd, MMM DD'); }


  return (
    <Box sx={styles}>
      <TeamWidget league={league} team={away.abbr} />
      <Box sx={middleStyles}>
        <span className="date">{displayDate}</span>
        <span className="time">
          {gameDay.format('h:mm A')}
        </span>
      </Box>
      <TeamWidget league={league} team={home.abbr} />
    </Box>
  );
};
