import * as React from 'react';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LEAGUE_GET_COLUMN_MAP } from './constants';


const paperStyles = {
  backgroundColor: 'grey.900',
  display: 'flex',
  mt: 1,
  ml: 1,
  mr: 1,
};

export const TeamTable = ({ scoring, selectedGame }) => {
  const { league } = selectedGame;
  console.log('scoring: ', scoring);
  const getColumns = LEAGUE_GET_COLUMN_MAP[league];

  const columns = React.useMemo(() => getColumns(), [getColumns]);
  return (
    <Paper elevation={1} sx={paperStyles}>
      <DataGrid
        autoHeight
        rowHeight={24}
        columns={columns}
        rows={[scoring.home, scoring.away]}
        onCellEditCommit={() => {}}
      />
    </Paper>
  );
};
