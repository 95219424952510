import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { getSchedule } from './actions';


export const initialState = {
  loadingSchedule: true,
  schedule: [],
  selectedDate: '',
  timezone: moment().tz(moment.tz.guess()).format('z'),
  client: process.env.REACT_APP_CLIENT_NAME,
  league: '',
};

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchedule.pending, (state) => {
        state.loadingSchedule = true;
      })
      .addCase(getSchedule.fulfilled, (state, action) => {
        state.loadingSchedule = false;
        state.schedule = action.payload;
      })
      .addCase(getSchedule.rejected, (state) => {
        state.schedule = [];
        state.loadingSchedule = false;
      });
  },
});

export const {
  setSelectedDate,
  setCalendarIsOpen,
  setSelectedTab,
} = dateSlice.actions;

export default dateSlice.reducer;
