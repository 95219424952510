import * as React from 'react';
import Box from '@mui/material/Box';
import Loader from 'commonComponents/Loader';
import PlayerTable from '../PlayerTable';
import TeamTable from '../TeamTable';


export const MarketContent = ({ selectedGame, loadingBoxscoreData, players, edits, selectedMarket }) => {
  if (!selectedGame) {
    return <div className="loader">Select a game!</div>;
  }
  if (loadingBoxscoreData) {
    return <Loader />;
  }
  if (selectedGame && !loadingBoxscoreData && selectedMarket === 0) {
    return (
      <Box sx={{ overflow: 'scroll', pb: 1 }}>
        <PlayerTable players={players.away} edits={edits.away} team="away" />
        <PlayerTable players={players.home} edits={edits.home} team="home" />
      </Box>
    );
  }
  if (selectedGame && !loadingBoxscoreData && selectedMarket === 1) {
    return <TeamTable />;
  }
  return null;
};
