import {
  KEY_PATH_MAP as NBA_KEY_PATHS,
  getColumns as getColumnsNba,
  KEY_TYPECAST_MAP as NBA_KEY_TYPECAST_MAP,
} from './nba/constants';

import {
  KEY_PATH_MAP as NFL_KEY_PATHS,
  getColumns as getColumnsNfl,
  KEY_TYPECAST_MAP as NFL_KEY_TYPECAST_MAP,
} from './nfl/constants';


export const LEAGUE_KEY_PATH_MAP = {
  NBA: NBA_KEY_PATHS,
  NFL: NFL_KEY_PATHS,
};

export const LEAGUE_GET_COLUMN_MAP = {
  NBA: getColumnsNba,
  NFL: getColumnsNfl,
};

export const LEAGUE_TYPECAST_MAP = {
  NBA: NBA_KEY_TYPECAST_MAP,
  NFL: NFL_KEY_TYPECAST_MAP,
};

