import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { TeamLogo } from 'utilities';
import SaveButton from './SaveButton';



const container = {
  display: 'flex',
  height: '72px',
};

const leftStyles = {
  display: 'flex',
  flex: '1 1 0px',
  p: 1,
  '& > *': {
    mr: 1,
    mt: 1,
  },
  img: { mt: 0.5 },
};

const middleStyles = {
  display: 'flex',
  justifyContent: 'center',
  flex: '1 1 0px',
  p: 1,
  '& > *': {
    mr: 1,
    mt: 1,
  },
  img: { mt: 0.5 },
};

const rightStyles = {
  display: 'flex',
  justifyContent: 'right',
  flex: '1 1 0px',
  p: 1,
  '& > *': {
    m: 1,
    p: 0,
  },
  svg: { color: 'secondary.main' },
};

export const CustomHeader = ({ team, selectedGame, rows, edits, savingEdits }) => (
  <Box sx={container}>
    <Box sx={leftStyles}>
      <TeamLogo league={selectedGame.league} team={selectedGame[team].abbr} />
      <h3>{selectedGame[team].name}</h3>
    </Box>
    <Box sx={middleStyles} />
    <Box sx={rightStyles}>
      {
        savingEdits[team]
          ? <CircularProgress size={35} />
          : <SaveButton selectedGame={selectedGame} rows={rows} edits={edits} team={team} />
      }
    </Box>
  </Box>
);
