import * as React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


const getEditedRows = (rows, edits) => (
  rows.filter((row) => !!edits.find((edit) => row.id === edit.id && edit.saveStatus === 'unsaved'))
);

export const SaveButton = ({ postBoxscoreEdits, selectedGame, rows, edits, team }) => {
  const { league, id: gameId } = selectedGame;

  return (
    <Tooltip title="Save Edits">
      <span>
        <IconButton
          disabled={!edits.filter((edit) => edit.saveStatus === 'unsaved').length}
          sx={{ maxHeight: '38px', maxWidth: '38px' }}
          onClick={() => postBoxscoreEdits({ league, players: getEditedRows(rows, edits), team, gameId })}
        >
          <SaveIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
