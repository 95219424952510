import clsx from 'clsx';
import { getCellValue, isFieldEdited } from '../helpers';


const DEFAULT_VALUES_MAP = {
  fullName: '',
  team: '',
  points: 0,
  rebounds: 0,
  assists: 0,
  steals: 0,
  blocks: 0,
  turnovers: 0,
  threePointsMade: 0,
  threePointsAtt: 0,
  twoPointsMade: 0,
  twoPointsAtt: 0,
  freeThrowsMade: 0,
  freeThrowsAtt: 0,
  minutes: '00:00',
  isCancelled: false,
};

export const KEY_PATH_MAP = {
  full_name: ['fullName'],
  team: ['team'],
  points: ['points'],
  rebounds: ['rebounds'],
  assists: ['assists'],
  steals: ['steals'],
  blocks: ['blocks'],
  turnovers: ['turnovers'],
  three_points_made: ['three_points_made'],
  three_points_att: ['three_points_att'],
  two_points_made: ['two_points_made'],
  two_points_att: ['two_points_att'],
  free_throws_made: ['free_throws_made'],
  free_throws_att: ['free_throws_att'],
  minutes: ['minutes'],
  isCancelled: ['isCancelled'],
};

export const KEY_TYPECAST_MAP = {
  points: (val) => parseInt(val, 10),
  rebounds: (val) => parseInt(val, 10),
  assists: (val) => parseInt(val, 10),
  steals: (val) => parseInt(val, 10),
  blocks: (val) => parseInt(val, 10),
  turnovers: (val) => parseInt(val, 10),
  three_points_made: (val) => parseInt(val, 10),
  three_points_att: (val) => parseInt(val, 10),
  two_points_made: (val) => parseInt(val, 10),
  two_points_att: (val) => parseInt(val, 10),
  free_throws_made: (val) => parseInt(val, 10),
  free_throws_att: (val) => parseInt(val, 10),
  minutes: (val) => String(val),
  isCancelled: (val) => !!val,
};

export const DEFAULT_COLUMN = (edits, players) => ({
  headerAlign: 'left',
  align: 'left',
  editable: true,
  sortable: true,
  cellClassName: ({ id, field, value }) => clsx('', {
    edited: isFieldEdited(KEY_PATH_MAP, id, field, value, edits, players),
  }),
});

export const getColumns = (edits, players) => [
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'full_name',
    headerName: 'Name',
    width: 170,
    editable: false,
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.full_name, DEFAULT_VALUES_MAP.fullName),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'points',
    headerName: 'P',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.points, DEFAULT_VALUES_MAP.points),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'rebounds',
    headerName: 'R',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.rebounds, DEFAULT_VALUES_MAP.rebounds),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'assists',
    headerName: 'A',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.assists, DEFAULT_VALUES_MAP.assists),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'steals',
    headerName: 'S',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.steals, DEFAULT_VALUES_MAP.steals),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'blocks',
    headerName: 'B',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.blocks, DEFAULT_VALUES_MAP.blocks),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'turnovers',
    headerName: 'T',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.turnovers, DEFAULT_VALUES_MAP.turnovers),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'three_points_made',
    headerName: 'F3M',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.three_points_made, DEFAULT_VALUES_MAP.threePointsMade),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'three_points_att',
    headerName: 'F3A',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.three_points_att, DEFAULT_VALUES_MAP.threePointsAtt),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'two_points_made',
    headerName: 'F2M',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.two_points_made, DEFAULT_VALUES_MAP.twoPointsMade),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'two_points_att',
    headerName: 'F2A',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.two_points_att, DEFAULT_VALUES_MAP.twoPointsAtt),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'free_throws_made',
    headerName: 'FTM',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.free_throws_made, DEFAULT_VALUES_MAP.freeThrowsMade),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'free_throws_att',
    headerName: 'FTA',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.free_throws_att, DEFAULT_VALUES_MAP.freeThrowsAtt),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'minutes',
    headerName: 'Mins',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.minutes, DEFAULT_VALUES_MAP.minutes),
  },
  {
    ...DEFAULT_COLUMN(edits, players),
    field: 'isCancelled',
    headerName: 'Cancelled',
    valueGetter: ({ row }) => getCellValue(row, KEY_PATH_MAP.isCancelled, DEFAULT_VALUES_MAP.isCancelled),
    type: 'boolean',
    align: 'center',
  },
];
