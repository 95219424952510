
import { setSelectedMarket } from 'pages/Settlements/reducers/settlement/settlementSlice';


export const mapStateToProps = (state) => ({
  selectedMarket: state.settlements.selectedMarket,
});

export const mapDispatchToProps = {
  setSelectedMarket,
};
