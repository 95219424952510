import { createAsyncThunk } from '@reduxjs/toolkit';
import sources from 'api/sources';


export const getSchedule = createAsyncThunk(
  'date/getSchedule',
  async ({
    date, timezone, client, league,
  }) => {
    const queryParams = { date, timezone, client, type: 'game' };
    if (league) queryParams.league = league;

    const { data } = await sources.schedule.get(queryParams);
    return data.data;
  },
);
