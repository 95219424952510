import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


const styles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'left',
  pl: 1,
  position: 'sticky',
  top: '64px',
  backgroundColor: 'grey.900',
  zIndex: 1,
};

export const MarketTabs = ({ selectedMarket, setSelectedMarket }) => {
  const handleChange = (event, newValue) => {
    setSelectedMarket(newValue);
  };

  return (
    <Box sx={styles}>
      <Tabs value={selectedMarket} onChange={handleChange} aria-label="market type tabs">
        <Tab label="Player Markets" />
        <Tab label="Team Markets" />
      </Tabs>
    </Box>
  );
};
