import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
    overflow: 'none',
    '& .loader': {
      margin: 'auto',
      color: '#E4AE3A',
      fontWeight: 800,
      fontSize: 'x-large',
    },
  },
}));
