import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import store from 'store';
import Routes from 'routes';
import theme from 'theme';
import 'App.css';


const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  </ThemeProvider>
);

export default App;
