import { setSelectedDate } from 'pages/Settlements/reducers/date/dateSlice';


export const mapStateToProps = (state) => ({
  selectedDate: state.date.selectedDate,
});

export const mapDispatchToProps = {
  setSelectedDate,
};
