import { createTheme } from '@mui/material/styles';
import { teamColorMap } from './teams';


const theme = {
  spacing: 8,
  palette: {
    mode: 'dark',
    primary: {
      main: '#673AB7',
    },
    secondary: {
      main: '#B39DDB',
    },
    transparentError: {
      main: '#D32F2F2E',
    },
    teams: teamColorMap,
    loader: '#E4AE3A',
  },
};

const darkTheme = createTheme(theme);
export default darkTheme;
