import merge from 'deepmerge';


export const accessWithKeyArray = (obj, keys) => {
  if (!keys || !keys.length) return undefined;

  const value = obj[keys[0]];
  if (!value || keys.length === 1) return value;

  return accessWithKeyArray(value, keys.slice(1));
};

export const getCellValue = (row, keys, defaultValue = null) => {
  const value = accessWithKeyArray(row, keys);
  if (value === undefined || value === '' || value === null) return defaultValue;
  return value;
};

export const buildObjectFromPath = (path, value) => {
  if (path.length === 1) return { [path[0]]: value };
  return { [path[0]]: buildObjectFromPath(path.slice(1), value) };
};

export const findPlayerByIdAndUpdate = (players, edit, keyMap) => {
  const { id, field, value } = edit;

  const updatedPlayers = players.map((player) => {
    if (player.id === id) {
      return merge(player, buildObjectFromPath(keyMap[field], value));
    }
    return player;
  });

  return updatedPlayers;
};

export const mergeEdits = (keyMap, edits, players) => {
  if (edits === []) return players;

  let withEditsMerged = players;

  edits.forEach((edit) => {
    withEditsMerged = findPlayerByIdAndUpdate(withEditsMerged, edit, keyMap);
  });

  return withEditsMerged;
};

export const isFieldEdited = (keyMap, id, field, value, edits, players) => {
  const cellInEditHistory = (edits
    .filter((edit) => edit.id === id && edit.field === field && edit.saveStatus !== 'saved').length > 0);
  if (!cellInEditHistory) return false;

  const row = players.filter((player) => player.id === id);
  const boxscoreValue = accessWithKeyArray(...row, keyMap[field]);

  if (boxscoreValue === value) return false;

  return true;
};
