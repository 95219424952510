import { setSelectedGame } from 'pages/Settlements/reducers/settlement/settlementSlice';


export const mapStateToProps = (state) => ({
  selectedGame: state.settlements.selectedGame,
});

export const mapDispatchToProps = {
  setSelectedGame,
};
